<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row mr>
        <e-col>
          <date-input label="Data (De)" v-model="filters.data1"/>
        </e-col>

        <e-col>
          <date-input label="Data (Até)" v-model="filters.data2"/>
        </e-col>

        <e-col>
          <erp-s-field
              view="tl"
              label="Filtro Data"
          >
            <erp-select
                :options="[
                          {label: 'Criação', value: 'createdAt'},
                          {label: 'Início', value: 'startTime'},
                          {label: 'Prazo', value: 'deadline'},
                      ]"
                v-model="filters.typeDate" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Pessoa"
          >
            <global-pessoa-input link :limit="10" class="erp-input-fake" v-model="filters.participants" type="user" />
          </erp-s-field>
        </e-col>

        <e-col style="min-width: 90%">
          <status-task-select field-view="tl" multiple placeholder="Todos" v-model="filters.status" code-as-value />
        </e-col>

        <e-col style="min-width: 90%">
          <type-task-select field-view="tl" placeholder="Todos" multiple v-model="filters.tipo" />
        </e-col>

          <e-col style="min-width: 170px">
            <task-priority-select size="2" field-view="tl" label-width="90px" @keydown.enter.native="buscar" v-model="filters.priority" />
          </e-col>
          <e-col style="min-width: 170px">
            <tribunal-select size="2" field-view="tl" label-width="90px" v-model="filters.tribunal" />
          </e-col>
          <e-col style="min-width: 170px">
            <comarca-select size="2" field-view="tl" label-width="90px" v-model="filters.comarca" :tribunal="filters.tribunal" :limit="10000" />
          </e-col>
          <e-col style="min-width: 170px">
            <vara-select size="2" field-view="tl" label-width="90px" v-model="filters.vara" :comarca="filters.comarca" />
          </e-col>
        <e-col>
          <erp-s-field
              view="tl"
              label="Layout"
          >
            <erp-select
                :options="[
                          {label: 'Indicador', value: 'indicador'},
                          {label: 'Relação', value: 'relacao'},
                      ]"
                size="2"
                v-model="filters.layout" />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar(formato)" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpBox,
  ErpLabel,
  ErpSelect
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import {date, URadio} from 'uloc-vue'
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {relatorio} from "@/domain/relatorios/services"
import {datePtToEn} from "@/utils/date"
import createPrint from "@/components/relatorios/components/customPrint/createPrint"
import GlobalPessoaInput from "@/components/pessoa/components/include/pessoa/PessoaInput"
import StatusTaskSelect from "@/components/sltarefas/components/inputs/StatusTaskSelect"
import TypeTaskSelect from "@/components/sltarefas/components/inputs/TypeTaskSelect"
import TaskPrioritySelect from "@/components/sltarefas/components/inputs/TaskPrioritySelect"
import TribunalSelect from "@/components/processos/components/processos/include/TribunalSelect"
import ComarcaSelect from "@/components/processos/components/processos/include/ComarcaSelect"
import VaraSelect from "@/components/processos/components/processos/include/VaraSelect"

let filters = {
  data1: null,
  data2: null,
  typeDate: 'deadline',
  id: null,
  participants: null,
  status: [],
  tipo: [],
  priority: null,
  tribunal: null,
  comarca: null,
  vara: null,
  layout: 'indicador'
}

export default {
  name: 'RelatorioTarefasWindow',
  mixins: [mixinPrint],
  data() {
    return {
      formato: 'html',
      filters: JSON.parse(JSON.stringify(filters))
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    createPrint: createPrint,
    actionPrint (action) {
      console.log('Print Action ', action)
      this.gerar(action)
    },
    gerar(formato = 'html') {
      let data1, data2
      let filters = JSON.parse(JSON.stringify(this.filters))
      if (filters.data1) {
        data1 = datePtToEn(filters.data1)
        data2 = datePtToEn(filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        filters.data1 = data1
        filters.data2 = data2
      }
      if (filters.participants && Array.isArray(filters.participants) && filters.participants.length) {
        filters.participants = filters.participants.map(p => p.id)
      }
      if (filters.participants && filters.participants.id) {
        filters.participants = [filters.participants.id]
      }

      this.loading = true
      relatorio('tarefas', filters, formato)
          .then(response => {
            this.loading = false
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint'), 'gerencial', 'Tarefas', 'landscape')
          })
          .catch(error => {
            this.loading = false
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    }
  },
  components: {
    VaraSelect,
    ComarcaSelect,
    TribunalSelect,
    TaskPrioritySelect,
    TypeTaskSelect,
    StatusTaskSelect,
    GlobalPessoaInput,
    DateInput,
    ECol,
    ERow,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    // ErpInput,
    // HelperInputBtn,
    // ErpBox,
    // ErpLabel
    // UEditor,
    // URadio,
    ErpSelect
  }
}
</script>
